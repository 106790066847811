.background-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-image::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-image: url('https://jashanzprimary.s3.ap-south-1.amazonaws.com/company_logo+(1).png');
  background-size: cover;
  background-position: center;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.1;
}
