/* Home.css */
.appBar {
    background-color: #000;
    color: #fff;
  }
  
  .logo {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  
  .logoImage {
    width: 50px;
    height: 50px;
    filter: invert(100%);
    margin-right: 10px;
  }
  
  .mainContent {
    color: #000;
    padding-top: 80px;
  }
  
  .welcomeSection {
    text-align: center;
    margin-top: 50px;
  }
  
  .aboutSection {
    margin-top: 80px;
  }
  
  .footer {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 20px 0;
  }
  
  .animated-product {
    margin-bottom: 30px;
  }
  
  .product-logo {
    max-width: 200px;
    margin: 0 auto;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set height to fill the viewport vertically */
}

.product-card {
    background-color: #fff; /* Card background color */
    border-radius: 20px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(172, 88, 88, 0.1); /* Box shadow */
    padding: 20px; /* Padding around content */
    display: flex; /* Display as flex for better alignment */
    align-items: center; /* Align items vertically */
    width: 250px; /* Fixed width */
    height: 250px; /* Fixed height */
    justify-content: center; /* Center horizontally */
    margin: 20px;
    cursor: pointer;
}


.product-logo {
    width: 200px; /* Fixed width for the logo */
    height: 200px; /* Maintain aspect ratio */
    border-radius: 8px; /* Rounded corners for the image */
}

.product-info {
    margin-left: 20px; /* Spacing between image and text */
}

.product-title {
    font-size: 1.2rem; /* Title font size */
    margin-bottom: 10px; /* Spacing below title */
}

.product-description {
    font-size: 1rem; /* Description font size */
    color: #555; /* Description text color */
}

.animated-product.left {
    animation: slide-in-left 1s ease forwards;
    opacity: 0;
    margin-bottom: 20px;
}

.animated-product.right {
    animation: slide-in-right 1s ease forwards;
    opacity: 0;
    margin-bottom: 20px;
}

@keyframes slide-in-left {
    from {
        transform: translateX(-50px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    from {
        transform: translateX(50px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
.bg-app{
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 80%;
  background-image: url('https://jashanzprimary.s3.ap-south-1.amazonaws.com/company_logo+(1).png');
  background-size: cover;
  background-position: center;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.3;
}